<template>
    <div>
        <el-row justify="space-between">
            <el-col :sm="24" :md="12" :lg="12" v-if="checkUsrPermissions('Create Welfare Transaction')">
                <el-button type="primary" @click="fundDialog=true">Add new Fund</el-button>
            </el-col>
            <el-col :sm="24" :md="9" :lg="9" class="" v-if="authUser.user_type == 'executive'">
                <el-select @change="getItems()" class="m-2" v-model="center_id" placeholder="Select Center" size="large">
                <el-option
                    v-for="item in centers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-col>
            <el-col :sm="12" :md="3" :lg="3" class="text-right">
                <el-button type="warning" size="large" @click="getItems" circle>
                <font-awesome-icon icon="fa-solid fa-rotate-right"/>
                </el-button>
            </el-col>
        </el-row>

        <el-row :gutter="20" align="middle">
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <p class="demonstration">Start date</p>
                <el-date-picker
                    v-model="filter.start_date"
                    type="date"
                    class="w-100"
                    placeholder="Pick a day"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="large"
                />
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <p class="demonstration">End date</p>
                <el-date-picker
                    v-model="filter.end_date"
                    type="date"
                    class="w-100"
                    placeholder="Pick a day"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="large"
                />
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                <p>Select donor</p>
                <el-select v-model="filter.donor_id" class="m-2" placeholder="Select donor " size="large" style="width: 100%;">
                        <el-option
                        v-for="item in donors"
                        :key="item.donor_id"
                        :label="item.name"
                        :value="item.id"
                        />
                    </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                <p>Trx type</p>
                <el-select v-model="filter.trx_type" class="m-2" placeholder="Select" size="large">
                <el-option
                    label="Debit"
                    value="debit"
                />
                <el-option
                    label="Credit"
                    value="credit"
                />
                </el-select>
            </el-col>
            
            
            <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4" class="mt-40">
                <el-button type="primary" size="large" @click="getItems" round
                >Filter
                </el-button
                >
                <el-button type="danger" size="large" @click="clear" round
                >Clear
                </el-button
                >
            </el-col>
        </el-row>

        <br/>

        <el-table :data="items" border v-loading="loading" >
            <el-table-column prop="donor_name" label="Donor">
                <template #default="scope">
                    {{  scope.row.donor_name ? scope.row.donor_name : 'n/a' }}
                </template>
            </el-table-column>
            <el-table-column prop="trxid" label="Transaction ID"></el-table-column>
            <el-table-column prop="invoice_no" label="Invoice No">
                <template #default="scope">
                    {{  scope.row.invoice_no ? scope.row.invoice_no : 'n/a' }}
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="Amount"></el-table-column>
            <!-- <el-table-column prop="trxid" label="ID"></el-table-column> -->
            <el-table-column prop="trx_type" label="Transaction type">
                <template #default="scope">
                    <el-tag class="ml-2" :type="scope.row.trx_type == 'credit' ? 'success' : 'danger'">{{  scope.row.trx_type }}</el-tag>

                </template>
            </el-table-column>
            <el-table-column prop="orko_balance" label="Orko balance"></el-table-column>
            <el-table-column prop="created_at" label="Created date"></el-table-column>
        </el-table>
        <el-pagination
            v-model:current-page="currentPage"
            :page-size="per_page"
            layout="total, prev, pager, next"
            :total="total_count"
            @current-change="getItems"
        />

        <el-dialog
            v-model="fundDialog"
            title="Create Welfare fund"
            class="appointment-status-modal"
            size="lg"
        >
            <el-form
                :model="form"
                label-position="top"
                label-width="100px"
                ref="installment">

                <el-row :gutter="20">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                    <p class="demonstration"> Select Donor</p>
                    <el-select v-model="form.donor_id" class="m-2" placeholder="Select donor " size="large" style="width: 100%;">
                        <el-option
                        v-for="item in donors"
                        :key="item.donor_id"
                        :label="item.name"
                        :value="item.id"
                        />
                    </el-select>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                    <p class="demonstration"> Amount</p>
                    <el-input type="number" v-model="form.amount" :min="0"  /> <br/>  
                    
                </el-col>
                <br/>
                </el-row>
                <br/>
            <div class="text-center">
                <el-button type="primary" @click="submitFund()">
                Confirm
                </el-button>
            </div>
            </el-form>
        
        </el-dialog>

    </div>
</template>
<script>
import axios from 'axios';
import { CenterMixins } from '@/mixins/CenterMixins';
import { mapGetters } from 'vuex';
export default {
    name: 'WelfareFund',
    mixins: [CenterMixins],
    data() {
        return {
            items: [],
            total_count: 0,
            currentPage: 0,
            per_page: 20,
            filter: {
                start_date: null,
                end_date: null,
                trx_type: '',
                donor_id: '',
            },
            fundDialog: false,
            form: {
                donor_id: '',
                amount: '',
            },
            donors: [],
        }
    },
    created() {
        this.getItems();
        this.getDonors();
    },
    computed: {
        ...mapGetters('auth', ['userInstitute', 'authUser']),
    },
    methods: {
        getItems(val){
            if (val) {
                const offset = val - 1;
                var correctValue = offset * this.per_page;
            }
            const filter = `?limit=${this.per_page}&offset=${correctValue || 0}${this.filter.start_date ? '&start_date='+this.filter.start_date : ''}${this.filter.end_date ? '&end_date='+this.filter.end_date : ''}${this.filter.donor_id ? '&donor_id='+this.filter.donor_id : ''}${this.filter.trx_type ? '&trx_type='+this.filter.trx_type : ''}`;

            axios.get(`api/v2/welfare/transactions${filter}`)
            .then(res => {
                this.items = res.data.data;
                this.total_count = res.data.total_count;
            })
        },
        clear() {
            this.filter = {};
            this.getItems();
        },
        getDonors() {
            axios.get(`/api/v2/donors`)
            .then(res => {
                this.donors = res.data.data;
            })
        },
        submitFund() {
            axios.post(`/api/v2/welfare/transactions`, this.form)
            .then((res) => {
            if (res.data.status_code == 200) {
                this.$notify({
                title: 'Success',
                message: 'Fund Successfully Added',
                type: 'success',
                duration: 3000,
                });

                this.fundDialog = false;
                this.getItems();
                
            } else {
                this.$notify({
                title: 'Failed',
                message: res.data.message,
                type: 'danger',
                duration: 3000,
                });
            }
            
            this.loading = false;
            });
        }
    }
}
</script>
<style>
.w-100 {
  width: 100% !important;
}
</style>